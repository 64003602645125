import React, { useRef, useEffect, useState } from 'react'
import UserVideo from "./userWindow"
import { Toggler, MediaInit } from "../utils/utilityFunctions"
import { io } from "socket.io-client"
import Peer from "simple-peer"
import { Icon } from '@iconify/react';
import micMute from '@iconify/icons-bi/mic-mute';
import micIcon from '@iconify/icons-bi/mic';
import emojiIcon from '@iconify/icons-bi/emoji-neutral-fill';
import handRight from '@iconify/icons-bx/bxs-hand-up';
import cameraVideo from '@iconify/icons-bi/camera-video';
import cameraVideoOff from '@iconify/icons-bi/camera-video-off';
import bxsMessageAltDetail from '@iconify/icons-bx/bxs-message-alt-detail';
import phoneOff from '@iconify/icons-carbon/phone-off';
import userList from '@iconify/icons-carbon/user-multiple'
import MicrosoftTeams from "../assets/logo_als.png";
import shareScreenStart28Filled from '@iconify/icons-fluent/share-screen-start-28-filled';
import shareScreenStop20Filled from '@iconify/icons-fluent/share-screen-stop-20-filled';
import overflowMenuVertical from '@iconify/icons-carbon/overflow-menu-vertical';
import record48Regular from '@iconify/icons-fluent/record-48-regular';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
import Chats from "./Chats";
import { connect } from 'react-redux';
import "../styles/meet.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Transcript from './Transcript'
import { prodUrl as url } from "../Config/config.json"
import axios from "axios"
import Participants from "./Participants";
import hark from 'hark'
import AlertDialog from "./modal/AlertDialog";
import likeIconEmoji from "../assets/images/like.png"
import hahaIconEmoji from "../assets/images/haha.png"
import supportIconEmoji from "../assets/images/support.png"
import wowIconEmoji from "../assets/images/wow.png"
import loveIconEmoji from "../assets/images/love.png"
import Emoji from "../utils/Emoji";

//  Create Ref component for each peer joining
const CreateRef = ({ peer, style, options, styleCam,focusRef }) => {
  // Ref for each peer
  //console.log(peer)
  const clientRef = useRef();
  style = {...style, cursor: "pointer"}
  // check whether peer video and audio setting before rendering them
  var optionUser = peer.options ? peer.options : { video: false, audio: false };
  if (options)
  {
    optionUser = options;
  }
  useEffect(() => {
    // Assign stream to ref on event "stream" triggered
    peer.peer.on("stream", (stream) => {
      //console.log(stream.getTracks())
      if (clientRef && clientRef.current)
        clientRef.current.srcObject = stream
      peer.stream = stream
    })

  }, [])

  const clickStreamFocusMode = (p) => {
    if(focusRef && focusRef.videoRef.current) {
      //console.log(p)
      focusRef.videoRef.current.srcObject =  clientRef.current.srcObject
      focusRef.nameRef.current.innerHTML =  p.name
    }
  }

// Return the video or off video element on the basis of stream enabled or not
// Destroyed stream should not be re-rendered
  return peer.destroyed ? null : <>
  <div onClick={() => {clickStreamFocusMode(peer)}} key={peer.peerID} id={peer.peerID + "-cam-id"} className="video-box-br" style={style}>
    <div className="off-cam-container" style={{ display: optionUser.video ? "flex" : "none" }} id={peer.peerID + "-video_on"}>
      <UserVideo
        hostRef={clientRef}
        muted={false}
        style={styleCam}
      />
      <div className="canvas-audio" style={{display: "none"}} id={peer.peerID + "-ownAudioCameraOnId"}>
        <div className="wave"></div>
      </div>
      <div className="member-name">
        {peer.name}
      </div>
    </div>
    <div
      className="off-cam-container"
      style={{ display: !optionUser.video ? "flex" : "none" }}
      id={peer.peerID + "-video_off"}>
      <div className="member-name-initial">{peer.name.slice(0, 1)}</div>
      <div className="canvas-audio" style={{display: "none"}} id={peer.peerID + "-ownAudioId"}>
        <div className="wave"></div>
      </div>
      <div className="member-name">
        {peer.name}
      </div>
    </div>
  </div>
  </>


}

const Meet = (props) => {

  const {
    camera,
    mic,
    setMic,
    setCamera,
    setStream,
    name,
    setSocket,
    userId,
    isAdmin
  } = props;

  const [namaUser, setNamaUser] = useState('')
  // Ref for the user's video (not peers)
  const hostRef = useRef()
  // List of peers
  const [peersUser, setPeers] = useState([]);
  // state of user video, audio option updates
  const [userUpdate, setUserUpdate] = useState();
  const [volumeUpdate, setVolumeUpdate] = useState();
  const [chatBadge, setChatBadge] = useState(false)
  const socketRef = useRef();
  const peersRef = useRef([]);
  const chatsRef = useRef([]);
  // Storing peers for screen Share in ref
  const screenSharesRef = useRef([]);
  // Storing peers for screen Share as a state
  const [screenShares, setScreenShares] = useState([])
  // screenStream is to store screen stream
  const screenStream = useRef()
  // screenStreamComponent is ref video screen share element
  const screenStreamComponent = useRef()
  const focusModeScreen = useRef()
  const focusNameModeScreen = useRef()
  // Storing screen streams for each person
  const userScreenStreams = useRef([])
  // to open chat panel
  const [openChat, setOpenChat] = useState(false);
  //set array of chats
  const [chats, setChats] = useState([])
  const [openParticipant, setOpenParticipant] = useState(false);
  //set array of chats
  const [participant, setParticipant] = useState([])
  //set array of transcripts
  const [transcripts, setTranscripts] = useState([])
  const [FrontalTranscript,setFrontalTranscript] = useState('')
  // store transcripts
  const transcriptsRef = useRef([])
  //open transcripts panel
  const [transcriptEnabled,setTranscriptEnabled] = useState(false)
  const [openTranscripts, setOpenTranscripts] = useState(false);
  // set state of screen sharing, enabled and presenter
  const [screenSharingEnabled, setScreenSharingEnabled] = useState({ enabled: false, presenter: null })
  const screenSharingEnabledRef = useRef(false);
  //change wallpaper of meeting
  const [customBackground, setCustomBackground] = useState(false)
  // recordchunks which will be processed later on
  const [recordedChunks, setRecordedChunks] = useState({ enabled: false, chunks: [] })
  //store ref to media recording
  const mediaRecorder = useRef(null)
  const [styleOwnDiv, setStyleOwnDiv] = useState(null)
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [speaking, setSpeaking] = useState(false);
  const [showAlertJoin, setShowAlertJoin] = useState(false);
  const [userJoinName, setUserJoinName] = useState('');
  const [countUsers, setCountUsers] = useState(1);
  const divWidthRef = useRef();
  const [date, setDate] = useState(new Date());
  const [showModal,setShowModal] = useState({modalMenu: false, emojiMenu: false})
  const [layout,setLayout] = useState('grid')
  const [children, setChildren] = useState([])
  const [magicNum, setMagicNum] = useState(1)
  //id of meeting present in url
  const roomID = props.match.params.teamId;

  const getDivSize = () => {
    let newWidth = 1200
    let newHeight = 300
    if(divWidthRef.current !== null && divWidthRef.current !== undefined){
      newWidth = divWidthRef.current.clientWidth;
      newHeight = divWidthRef.current.clientHeight;
    }
    setWidth(newWidth);

    setHeight(newHeight);
  };

  function refreshClock() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);


  useEffect(() => {
    getDivSize()
  }, []);

 /* useEffect(() => {
   resizeVideo()
  }, [countUsers]);*/

  useEffect(() => {
    window.addEventListener("resize", getDivSize);
  }, []);

  useEffect(() => {
    if(mic)
    {
      if(camera) {
        startHandleAudioContext({stream: hostRef.current.srcObject,idAudio: "ownAudioOnCameraId"})
      } else {
        startHandleAudioContext({stream: hostRef.current.srcObject,idAudio: "ownAudioId"})
      }


    }

  }, [mic,camera])

  useEffect(() => {
    if (mic) {
      sendAudioActivity()
    } else {
      setSpeaking(false)
      sendAudioActivity()
    }
  }, [speaking])


  // user video styling
  const style = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "12px",
  }
// start streaming when join room
  useEffect(() => {
    setNamaUser(localStorage.getItem('nama_pengguna'))
    startStream()
  }, [])


 //run when ever a new person joins and share screen if enabled for new user 
  useEffect(() => {
    var i = 0;
    screenSharesRef.current.forEach((peer) => {
      peer.peer.on("stream", (stream) => {
        userScreenStreams.current.push({ peerID: peer.peerID, stream })
        i++;
        if (i === screenSharesRef.current.length && !screenSharingEnabledRef.current) {
          socketRef.current.emit("screen streaming running for new user", { roomID })
        }
      })
    })

  }, [screenShares, screenSharesRef])


  // any kind of update from peers should be reflected by changing the video or audio over other peers
  useEffect(() => {

    if (userUpdate) {
      if (userUpdate.video) {
        if (document.getElementById(userUpdate.id + "-video_on") && document.getElementById(userUpdate.id + "-video_off")) {
          document.getElementById(userUpdate.id + "-video_on").style.display = "block"
          document.getElementById(userUpdate.id + "-video_off").style.display = "none"
        }
      }
      else {
        if (document.getElementById(userUpdate.id + "-video_on") && document.getElementById(userUpdate.id + "-video_off")) {
          document.getElementById(userUpdate.id + "-video_on").style.display = "none"
          document.getElementById(userUpdate.id + "-video_off").style.display = "flex"
        }
      }
    }
  }, [userUpdate])

  useEffect(() => {

    if (volumeUpdate) {

        if (volumeUpdate.video) {
          if(volumeUpdate.isSpeaking) {
            if(document.getElementById(volumeUpdate.id + "-ownAudioCameraOnId") !== undefined && document.getElementById(volumeUpdate.id + "-ownAudioCameraOnId") !== null) {
              document.getElementById(volumeUpdate.id + "-ownAudioCameraOnId").style.display = "flex"
            }
          } else {
            if(document.getElementById(volumeUpdate.id + "-ownAudioCameraOnId") !== undefined && document.getElementById(volumeUpdate.id + "-ownAudioCameraOnId") !== null) {
              document.getElementById(volumeUpdate.id + "-ownAudioCameraOnId").style.display = "none"
            }
          }
        }
        else {
          if(volumeUpdate.isSpeaking) {
            if(document.getElementById(volumeUpdate.id + "-ownAudioId") !== undefined && document.getElementById(volumeUpdate.id + "-ownAudioId") !== null){
              document.getElementById(volumeUpdate.id + "-ownAudioId").style.display = "flex"
            }

          } else {
            if(document.getElementById(volumeUpdate.id + "-ownAudioId") !== undefined && document.getElementById(volumeUpdate.id + "-ownAudioId") !== null) {
              document.getElementById(volumeUpdate.id + "-ownAudioId").style.display = "none"
            }
          }
        }

    }
  }, [volumeUpdate])


  // stop services before leaving the meeting if refreshing or closing tab by the user
  // disonnect when user leaves by explicitly clicking on leave button
  const disconnectMeet = () => {
    stopMediaRecorder()
    socketRef.current.emit("disconnectMeet", { name, roomID, userId })
    stopTranscripting()
    setTranscripts([])
    transcriptsRef.current = []
    setTimeout(() => {
      window.location = "/"
    },
      200);
  }

  const raiseHand = () => {
    //console.log('kirim')
    socketRef.current.emit("raisehand", { name, roomID, userId })
  }


  // startStream and set socket for user
  const startStream = () => {
    /*if (socket) {
      socketRef.current = socket
      createStream();
      startChat()
    }
    else {
      var socketNew = io.connect(`${url}`)
      socketNew.on('connect', () => {
        socketRef.current = socketNew;
        setSocket(socketNew)
        createStream();
        startChat()
      })
    }*/
   /* var socketNew = io.connect(`${url}`)
    socketNew.on('connect', () => {
      socketRef.current = socketNew;
      setSocket(socketNew)
      createStream();
      startChat()
    })*/
    //console.log(`nama${userId}`)
    const socketNew = io.connect(`${url}`)
    socketRef.current = socketNew
    setSocket(socketNew)
    createStream()
    startChat()
    startShowParticipant()
    //resizeVideo()

  }

  const sendAudioActivity = () => {
    if(socketRef.current)
    {
      socketRef.current.emit("audio level", {roomID: roomID, isSpeaking: speaking, video: camera})
    }

  }

  const startShowParticipant = () => {
    socketRef.current.on("participant", (userJoined) => {
      setParticipant(userJoined)
    });

    socketRef.current.on("mute", (payload) => {
      if (hostRef.current.srcObject && hostRef.current && hostRef) {
        hostRef.current.srcObject.getTracks().forEach(function (track) {
            track.enabled = false;
        });
        setMic(false);
      }
    });

    socketRef.current.on("off camera", (payload) => {
      if (hostRef.current.srcObject && hostRef.current && hostRef) {
        hostRef.current.srcObject.getTracks().forEach(function (track) {
          track.enabled = false;
        });
        setCamera(false);
      }
    });

    socketRef.current.on("user permit join", (payload) => {
      setShowAlertJoin(true)
      console.log(payload)
      setUserJoinName({socket: payload.userSocket, name: payload.userName})
    })
  }

  const reqShowParticipant = () => {
    socketRef.current.emit("user list", roomID);
  }

  const muteParticipant = (sockId) => {
    socketRef.current.emit("mute user", {
      id: sockId,
      video: camera,
      audio: false,
      roomID
    });
  }

  const offVideoParticipant = (sockId) => {
    socketRef.current.emit("off video", {
      id: sockId,
      video: false,
      audio: mic,
      roomID
    });
  }

  // socket to receive message enabled
  const startChat = () => {
    socketRef.current.on("receivedMessage", (message) => {
      setChatBadge(true)
      chatsRef.current = [...chatsRef.current, { name: message.name, message: message.message }];
      const chatsUpdated = chatsRef.current;
      setChats(chatsUpdated)
    })

    socketRef.current.on("raise users", ({ id, name }) => {
      //console.log('terima tangan')
      peersRef.current.filter(p => {
        if (p.peerID === id) {
          if (name === undefined)
            toast.dark(`${p.name} has raise hand`, {
              position: "top-left",
              hideProgressBar: true,
              autoClose: 1500
            });
        }
        return false
      });
      if (name !== undefined)
        toast.dark(`${name} has raise hand`, {
          position: "top-left",
          hideProgressBar: true,
          autoClose: 1500
        });
    });

    socketRef.current.on("emoji users", ({ id, name, typeEmoji }) => {
      //console.log('terima tangan')
        makeAnimationEmoji(typeEmoji,name)
    });
  }

  const focusScreen = streamVideo => {
     // if(streamVideo === null && streamVideo === undefined){
        if (hostRef.current.srcObject && hostRef.current && hostRef) {
          setLayout('focus')
          focusModeScreen.current.srcObject = hostRef.current.srcObject
          setShowModal({...showModal, modalMenu: false})
        }
     // }
  }

  const gridScreen = streamVideo => {
    // if(streamVideo === null && streamVideo === undefined){
    if (hostRef.current.srcObject && hostRef.current && hostRef) {
      setLayout('grid')
      setShowModal({...showModal, modalMenu: false})
    }
    // }
  }

  const clickOwnStreamFocusMode = () => {
    if(focusModeScreen && focusModeScreen.current) {
      //console.log(p)
      focusModeScreen.current.srcObject =  hostRef.current.srcObject
      focusNameModeScreen.current.innerHTML =  namaUser
    }
  }

  const makeAnimationEmoji = (type,nameOfUser) => {

    let addNum = magicNum
    setMagicNum(++addNum)
    setChildren([...children, <Emoji key={magicNum} nameUserEmoji={nameOfUser} emojiType={type} magicNum={magicNum} animationHasEnd={animationHasEnd}/>])

  }
  const createAnimation = (typeEmoji,nameOfUser) =>{
    socketRef.current.emit("useremoji", { name, roomID, userId,typeEmoji })
    makeAnimationEmoji(typeEmoji,nameOfUser)
  }
  const animationHasEnd = (childIndex)=>{
    let newChildren= children.filter(function(children){
      return children.props.magicNum!==childIndex
    })
    setChildren(newChildren);
  }

// screen sharing enabling and notifying each peer that screen shareing has started, also updating stream for each peer
  const screenShare = () => {
    if (!screenSharingEnabled.enabled) {
      setLayout("sharescreen")
      navigator.mediaDevices.getDisplayMedia({ video: true, audio: true }).then(screenStreamUpdate => {
        setScreenSharingEnabled({ enabled: true, presenter: socketRef.current.id })
        screenSharingEnabledRef.current = true
        screenStreamComponent.current.srcObject = screenStreamUpdate
        socketRef.current.emit("screen stream update", { updateStream: true, roomID, name })
        screenSharesRef.current.forEach((screenRef) => {
          if (screenStreamUpdate.getTracks()[1]) {
            screenRef.peer.replaceTrack(screenStream.current.getTracks()[1], screenStreamUpdate.getTracks()[1], screenStream.current)
            screenRef.peer.replaceTrack(screenStream.current.getTracks()[0], screenStreamUpdate.getTracks()[0], screenStream.current)
          }
          else if (screenStreamUpdate.getTracks()[0]) {
            screenRef.peer.replaceTrack(screenStream.current.getTracks()[1], screenStreamUpdate.getTracks()[0], screenStream.current)
          }
        })
        screenStreamUpdate.getVideoTracks()[0].onended = function () {

          stopMediaRecorder()
          setScreenSharingEnabled({ enabled: false, presenter: null })
          screenSharingEnabledRef.current = false
          if (screenStreamUpdate.getAudioTracks()[0])
            screenStreamUpdate.getAudioTracks()[0].stop();
          socketRef.current.emit("screen stream update", { updateStream: false, roomID, name })

        };
      }).catch(err => {
        toast.error(`Your device doesn't support screen share`, {
          position: "top-left",
          hideProgressBar: true,
          autoClose: 1500,

        });
      })
    }
    else {
      setScreenSharingEnabled({ enabled: false, presenter: null })
      screenSharingEnabledRef.current = false
      if (screenStreamComponent.current.srcObject.getAudioTracks()[0])
        screenStreamComponent.current.srcObject.getAudioTracks()[0].stop();
      screenStreamComponent.current.srcObject.getVideoTracks()[0].stop()

      socketRef.current.emit("screen stream update", { updateStream: false, roomID, name })
    }
  }


  const enableScreenSharingForNewUser = (peer) => {
    if (screenStreamComponent.current.srcObject.getAudioTracks()[0]) {
      peer.replaceTrack(screenStream.current.getTracks()[1], screenStreamComponent.current.srcObject.getTracks()[1], screenStream.current)
      peer.replaceTrack(screenStream.current.getTracks()[0], screenStreamComponent.current.srcObject.getTracks()[0], screenStream.current)
    }
    else {
      peer.replaceTrack(screenStream.current.getTracks()[1], screenStreamComponent.current.srcObject.getTracks()[0], screenStream.current)
    }
  }

// send message to each peer via sockets
  const sendMessage = (sendMessage) => {
    const payload = { name, message: sendMessage, senderId: socketRef.current.id, userId, roomID };
    socketRef.current.emit("send message", payload);
    chatsRef.current = [...chatsRef.current, { name: "You", message: payload.message }]
    const chatsUpdated = chatsRef.current;
    setChats(chatsUpdated)

  }


// variables for transcript in each room
  var accessToken = null;
  var uniqueMeetingId;
  var symblEndpoint;
  const ws = useRef()

// loading of transcript and get access token from server
  const startLoadingTranscript = (transcriptStream) => {
      setTranscriptEnabled(true)
      axios(
        {
          url: `${url}/transcriptToken`,
          method: 'GET'
        }).then(data => {
          toast.dark("Transcript is loading",{
            hideProgressBar:true,
            autoClose:1500,
            position:'top-left'
          })
          accessToken = data.data.accessToken
          uniqueMeetingId = btoa(roomID)
          symblEndpoint = `wss://api.symbl.ai/v1/realtime/insights/${uniqueMeetingId}?access_token=${accessToken}`;
          ws.current=new WebSocket(symblEndpoint)
            startTranscripting({ stream: transcriptStream, roomID, socketRef, name })


        })
  }

  const startHandleAudioContext = ({stream, idAudio}) => {
    const handleAudioSuccess = (stream) => {
      var max_level_L = 0;
      var old_level_L = 0;
      var cnvs = document.getElementById(idAudio);
      var cnvs_cntxt = cnvs.getContext("2d");

      //const AudioContext = window.AudioContext;
      const speech = hark(stream,{})
      speech.on('speaking', function (){
        //console.log("bicara")
        setSpeaking(true)
      })
      speech.on('stopped_speaking', function (){
        //console.log("stop")
        setSpeaking(false)
      })
      const context = new AudioContext();
      const source = context.createMediaStreamSource(stream);
      const processor = context.createScriptProcessor(1024, 1, 1);
      const gainNode = context.createGain();
      source.connect(gainNode);
      gainNode.connect(processor);
      processor.connect(context.destination);
      processor.onaudioprocess = (e) => {
        // convert to 16-bit payload
        const inputData = e.inputBuffer.getChannelData(0) || new Float32Array(this.bufferSize);
        const targetBuffer = new Int16Array(inputData.length);
        for (let index = inputData.length; index > 0; index--) {
          targetBuffer[index] = 32767 * Math.min(1, inputData[index]);
        }
        //audio stream
        let instant_L = 0.0;

        let sum_L = 0.0;
        for(var i = 0; i < inputData.length; ++i) {
          sum_L += inputData[i] * inputData[i];
        }
        instant_L = Math.sqrt(sum_L / inputData.length);
        max_level_L = Math.max(max_level_L, instant_L);
        instant_L = Math.max( instant_L, old_level_L -0.008 );
        old_level_L = instant_L;


        cnvs_cntxt.clearRect(0, 0, cnvs.width, cnvs.height);
        cnvs_cntxt.fillStyle = '#00ff00';
        cnvs_cntxt.fillRect(10,10,(cnvs.width-20)*(instant_L/max_level_L),(cnvs.height-20)); // x,y,w,h
      };
    };
    if(stream) {
      handleAudioSuccess(stream);
    }

  }

//start transcripting
  const startTranscripting = ({ stream, roomID, socketRef, name }) => {
    // Fired when a message is received from the WebSocket server
    ws.current.onmessage = (event) => {
      // You can find the conversationId in event.message.data.conversationId;
      const data = JSON.parse(event.data);
      if (data.type === 'message' && data.message.hasOwnProperty('data')) {
        console.log('conversationId', data.message.data.conversationId);
      }
      if (data.type === 'message_response') {
        var messagesConcatenation = "";
        for (let message of data.messages) {
          // console.log('Transcript (more accurate): ', message.payload.content);
          messagesConcatenation = messagesConcatenation + message.payload.content;

          transcriptsRef.current = [...transcriptsRef.current, { name: message.from.userId === userId ? 'You' : message.from.name, message: message.payload.content }]
          setTranscripts(transcriptsRef.current)
        }
        setFrontalTranscript('')
      }
      /*if (data.type === 'topic_response') {
        for (let topic of data.topics) {
          // console.log('Topic detected: ', topic.phrases)
        }
      }
      if (data.type === 'insight_response') {
        for (let insight of data.insights) {
          // console.log('Insight detected: ', insight.payload.content);
        }
      }*/
      if (data.type === 'message' && data.message.hasOwnProperty('punctuated')) {
        // console.log('Live transcript (less accurate): ', data)
        setFrontalTranscript( (data.message.user.userId === userId?'You':data.message.user.name) +": " + data.message.punctuated.transcript)
      }
      console.log(`Response type: ${data.type}. Object: `, data);
    };

    // Fired when the WebSocket closes unexpectedly due to an error or lost connetion
    ws.current.onerror = (err) => {
      toast.error("Facing issue with transcript")
      console.error(err);
    };

    // Fired when the WebSocket connection has been closed
    ws.current.onclose = (event) => {
      console.info('Connection to websocket closed');
    };

    // Fired when the connection succeeds.
    ws.current.onopen = (event) => {
      toast.dark("Transcript is ON", {
        hideProgressBar: true,
        position: 'top-left',
        autoClose: 1500
      })
      ws.current.send(JSON.stringify({
        type: 'start_request',
        meetingTitle: 'Websockets How-to', // Conversation name
        insightTypes: ['question', 'action_item'], // Will enable insight generation
        config: {
          confidenceThreshold: 0.5,
          languageCode: 'en-US',
          speechRecognition: {
            encoding: 'LINEAR16',
            sampleRateHertz: 44100,
          }
        },
        speaker: {
          userId: userId,
          name: name,
        }
      }));
    };

    /**
     * The callback function which fires after a user gives the browser permission to use
     * the computer's microphone. Starts a recording session which sends the audio stream to
     * the WebSocket endpoint for processing.
     */
    const handleSuccess = (stream) => {

      const AudioContext = window.AudioContext;
      const context = new AudioContext();
      const source = context.createMediaStreamSource(stream);
      const processor = context.createScriptProcessor(1024, 1, 1);
      const gainNode = context.createGain();
      source.connect(gainNode);
      gainNode.connect(processor);
      processor.connect(context.destination);
      processor.onaudioprocess = (e) => {
        // convert to 16-bit payload
        const inputData = e.inputBuffer.getChannelData(0) || new Float32Array(this.bufferSize);
        const targetBuffer = new Int16Array(inputData.length);
        for (let index = inputData.length; index > 0; index--) {
          targetBuffer[index] = 32767 * Math.min(1, inputData[index]);
        }
        // Send audio stream to websocket.
        if (ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(targetBuffer.buffer);
        }

      };
    };


    handleSuccess(stream);
  }

  const stopTranscripting = () => {
    if(ws.current)
    {
      setFrontalTranscript('')
    ws.current.send(JSON.stringify({
      "type": "stop_request"
    }));
    setTranscriptEnabled(false)
      toast.dark("Transcript stopped !", {
        hideProgressBar: true,
        position: 'top-left',
        autoClose: 1500
      })
    }
  }


  //create stream os user and add already present users
  function createStream() {
    MediaInit({ camera, mic, hostRef, setStream }).then((stream) => {
      setStream(stream);
      hostRef.current.srcObject = stream;
      screenStream.current = stream.clone();
      screenStream.current.getTracks().forEach(function (track) {
        if (track.kind === "audio") {
          track.enabled = false;
        }
        if (track.kind === "video") {
          track.enabled = false;
        }
      }
      )

      hostRef.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "audio") {
          track.enabled = mic;
        }
        if (track.kind === "video") {
          track.enabled = camera;
        }
      }
      )


      var options = { audio: mic, video: camera }
      // join room
      socketRef.current.emit("join room", { roomID, options, name, userId: userId, isAdmin: isAdmin });
      // if transcript is enabled or disabled
      if(camera) {
        startHandleAudioContext({stream: hostRef.current.srcObject,idAudio: "ownAudioOnCameraId"})
      } else {
        startHandleAudioContext({stream: hostRef.current.srcObject,idAudio: "ownAudioId"})
      }
      socketRef.current.on("transcript updated",payload=>{
        if(payload.enabled)
        {
          toast.dark(`Transcript enabled by ${payload.name}`,{
          position:'top-left',
          hideProgressBar:true
          })
          startLoadingTranscript(hostRef.current.srcObject)
        }
        else{
          toast.dark(`Transcript disabled by ${payload.name}`,{
            position:'top-left',
            hideProgressBar:true
            })
          stopTranscripting()
        }
      })
      // receive already present users
      socketRef.current.on("all users", (users) => {
      // add peers
        const peers = [];
        users.forEach((userID) => {
          const peer = createPeer(userID.id, socketRef.current.id, stream, options, name);
          peersRef.current.push({
            peerID: userID.id,
            peer,
            options: userID.options,
            name: userID.name,
            new: true,
            destroyed: false
          });
          peers.push({
            peerID: userID.id,
            peer,
            options: userID.options,
            name: userID.name
          });
          toast.dark(`${userID.name} is already present`, {
            position: "top-left",
            hideProgressBar: true,
            autoClose: 1500
          });
        });
        const userCount = users.length + 1
        setCountUsers(userCount)
        setPeers(peers);
        setParticipant(users);
        //resizeVideo()
        const peersForScreenStream = [];

        users.forEach((userID) => {
          const peer = createPeerForScreenShare(userID.id, socketRef.current.id, screenStream.current);
          screenSharesRef.current.push({
            peerID: userID.id + "-screen-share",
            peer,
            options: userID.options,
            name: userID.name
          });
          peersForScreenStream.push({
            peerID: userID.id + "-screen-share",
            peer,
            options: userID.options,
            name: userID.name
          });

        });
        setScreenShares(peersForScreenStream);

      });
      // add peer when a user joins
      socketRef.current.on("user joined", (payload) => {
        //console.log(payload)
        const peer = addPeer(payload.signal, payload.callerID, stream, payload.options, payload.name);
        peersRef.current.push({
          peerID: payload.callerID,
          peer,
          options: payload.options,
          name: payload.name,
          new: true,
          destroyed: false
        });
        const peerUpdate = peersRef.current.filter((p) => p.peerID !== payload.callerID);
        peerUpdate.push({
          peerID: payload.callerID,
          peer,
          options: payload.options,
          name: payload.name,

        })
       // document.getElementById("ownCamId").style.width = getMax(peerUpdate.length - 1)+"px"
      //  document.getElementById("ownCamId").style.height = (getMax(peerUpdate.length - 1) * 0.5625)+"px"
        setPeers(peerUpdate)
        setCountUsers(payload.userCount)
        toast.dark(`${payload.name} has joined`, {
          position: "top-left",
          hideProgressBar: true,
          autoClose: 1500
        });
      });
      // destroy peer when user leaves
      socketRef.current.on("user left", ({ id, name, userCount }) => {
        const peersBr = peersRef.current.filter((fil) => fil.peerID !== id);
        //console.log(userCount)
        //setPeers(peersBr);
        setCountUsers(userCount)
        let emptyPerson = 0;
        peersRef.current.filter((p) => {
          if (p.peerID === id) {
            p.destroyed = true;
            p.peer.destroy();
            if (name === undefined)
              toast.dark(`${p.name} has left`, {
                position: "top-left",
                hideProgressBar: true,
                autoClose: 1500
              });
          } else {
            if(document.getElementById(p.peerID + "-cam-id") !== undefined && document.getElementById(p.peerID + "-cam-id") !== null){
          //    document.getElementById(p.peerID + "-cam-id").style.width = getMax(peersBr.length)+"px"
           //   document.getElementById(p.peerID + "-cam-id").style.height = (getMax(peersBr.length) * 0.5625)+"px"
              emptyPerson = emptyPerson + 1;
            }

          }
          //const jumPeer =peersRef.current.length + 1;
          return false
        });
        if(emptyPerson == 0){
          //document.getElementById("ownCamId").style.width = getMax(0)+"px"
         // document.getElementById("ownCamId").style.height = (getMax(0) * 0.5625)+"px"
         // setPeers([]);
        } else {
         // document.getElementById("ownCamId").style.width = getMax(peersBr.length)+"px"
          //document.getElementById("ownCamId").style.height = (getMax(peersBr.length) * 0.5625)+"px"
        }
        // setStyleOwnDiv({margin: "10px", width: getMax(peersBr.length) + "px", height : (getMax(peersBr.length) * 0.5625) + "px"})
       // console.log("jumlah_peers"+peersRef.current.length)


        //resizeVideo(peersBr.length)
        //console.log("jumlah peer "+peersRef.current.length)

        if (name !== undefined)
          toast.dark(`${name} has left`, {
            position: "top-left",
            hideProgressBar: true,
            autoClose: 1500
          });
      });

      socketRef.current.on("receiving returned signal", (payload) => {
        const item = peersRef.current.find((p) => p.peerID === payload.id);
        item.peer.signal(payload.signal);
      });
      // any kind of update by peer should be reflected for others
      socketRef.current.on("change", (payload) => {
        setUserUpdate({ id: payload.id, video: payload.video, audio: payload.audio });
      });

      socketRef.current.on("audio users", (payload) => {
        console.log(payload)
        setVolumeUpdate({ id: payload.id, video: payload.video, audio: payload.mic, isSpeaking: payload.isSpeaking});
      });

      // add screen streams of users
      socketRef.current.on("user added screen stream", (payload) => {

        const peer = addPeerForScreenShare(payload.signal, payload.callerID, screenStream.current);
        screenSharesRef.current.push({
          peerID: payload.callerID + "-screen-share",
          peer,
        });
        const peerUpdate = screenSharesRef.current.filter((p) => p.peerID !== payload.callerID + "-screen-share");
        peerUpdate.push({
          peerID: payload.callerID + "-screen-share",
          peer,
        })
        setScreenShares(peerUpdate)
        if (screenSharingEnabledRef.current)
          enableScreenSharingForNewUser(peer)
      });

      // destroy screen stream if a user left
      socketRef.current.on("user left screen stream", (id) => {

        const index = screenSharesRef.current.findIndex((p) => p.peerID === id);

        if (index !== -1) {
          if (screenSharesRef.current[index]) {
            screenSharesRef.current[index].peer.destroy();
          }
        }
        const peers = screenSharesRef.current.filter((p) => p.peerID !== id);
        screenSharesRef.current = peers;

        setScreenShares(peers);
      });

      socketRef.current.on("receiving returned screen stream", (payload) => {
        const item = screenSharesRef.current.find((p) => p.peerID === payload.id);
        item.peer.signal(payload.signal);
      });

      socketRef.current.on("screen share update", payload => {
        if (payload.updateStream) {
          setScreenSharingEnabled({ enabled: true, presenter: payload.id })
          screenSharingEnabledRef.current = true
          const peer = userScreenStreams.current.find((peer) => peer.peerID === payload.id + "-screen-share");
          screenStreamComponent.current.srcObject = peer.stream;
          toast.dark(`${payload.name} started screen sharing`, {
            position: "top-left",
            hideProgressBar: true,
            autoClose: 1500
          })
        }
        else {
          setScreenSharingEnabled({ enabled: false, presenter: null })
          screenSharingEnabledRef.current = false
          toast.dark(`${payload.name} stopped screen sharing`, {
            position: "top-left",
            hideProgressBar: false,
            autoClose: 1500
          })
        }
      })

      

    }).catch(err => {
      toast.error('Devices not working properly', {
        position: 'top-left',
        hideProgressBar: true,
        autoClose: 1500
      })
      console.error(err)
    })
  }


// create peer for present users
  function createPeer(userToSignal, callerID, stream, options, name) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      config: {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
          {
            urls: "turn:47.254.255.212:3478",
            username: "anandhi",
            credential: "123456",
          },
        ],
      },
      stream

    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sending signal", {
        userToSignal,
        callerID,
        signal,
        options,
        name,
        roomID
      });
    });


    return peer;
  }

  // add  peer for new users joining
  function addPeer(incomingSignal, callerID, stream, options) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      config: {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
          {
            urls: "turn:47.254.255.212:3478",
            username: "anandhi",
            credential: "123456",
          },
        ],
      },
      stream
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("returning signal", { signal, callerID, options, name });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  // create peer for present users  for screen sharing
  function createPeerForScreenShare(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      config: {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
          {
            urls: "turn:47.254.255.212:3478",
            username: "anandhi",
            credential: "123456",
          },
        ],
      },
      stream

    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sending screen stream", {
        userToSignal,
        callerID,
        signal,

      });
    });


    return peer;
  }
  // add  peer for new users joining for screen sharing
  function addPeerForScreenShare(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      config: {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
          {
            urls: "turn:47.254.255.212:3478",
            username: "anandhi",
            credential: "123456",
          },
        ],
      },
      stream
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("returning screen stream", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

// toggle state when video or audio are updated
  const ToggleState = (kind, state, setState) => {
    if (hostRef.current.srcObject && hostRef.current && hostRef) {
      hostRef.current.srcObject.getTracks().forEach(function (track) {
        //console.log("masuk 2")
        if (track.kind === kind) {

          socketRef.current.emit("change", {
            id: socketRef.current.id,
            video: kind === "video" ? !camera : camera,
            audio: kind === "audio" ? !mic : mic,
            roomID
          });
          track.enabled = kind === "audio" ? !mic : !camera;
        }

        Toggler(state, setState)

      })
    }

  }

  // Media Recording start
  const startMediaRecorder = () => {

    var videos = document.getElementsByTagName('video')
    // connecting screenshare audio with users audio
    const audioContext = new AudioContext();
   // var audios = [];
    var dest = audioContext.createMediaStreamDestination();
    for (let video in Array.from(videos)) {
      if (videos[video].id !== 'user-own-video') {
        if (videos[video].srcObject.getAudioTracks().length !== 0)
          audioContext.createMediaStreamSource(videos[video].srcObject).connect(dest)
      }
      else {
        if (hostRef.current.srcObject.getAudioTracks().length !== 0)
          audioContext.createMediaStreamSource(hostRef.current.srcObject).connect(dest)
      }
    }

    var newScreenRecorderStream = new MediaStream([
      dest.stream.getAudioTracks()[0], 
    screenStreamComponent.current.srcObject.getVideoTracks()[0]])

    setRecordedChunks({ enabled: true, chunks: [...recordedChunks.chunks] })
    toast.info("Screen Recording Started", {
      position: 'top-left',
      hideProgressBar: true,
      autoClose: 1500
    })

    // add opus in mimeType so that firefox also supports
    var options = { mimeType: 'video/webm;codecs=vp8,opus' };
    mediaRecorder.current = new MediaRecorder(newScreenRecorderStream, options);

    mediaRecorder.current.ondataavailable = handleDataAvailable;
    mediaRecorder.current.start();

    function handleDataAvailable(event) {

      if (event.data.size > 0) {
        recordedChunks.chunks.push(event.data);
        setRecordedChunks({ enabled: recordedChunks.enabled, chunks: [...recordedChunks.chunks] })
        download();
      }
    }
  }
  const download = () => {

    var blob = new Blob(recordedChunks.chunks, {
      type: "video/webm"
    });
    var url = URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = `Recording-${new Date()}.webm`;
    a.click();
    window.URL.revokeObjectURL(url);
    setRecordedChunks({ enabled: false, chunks: [] })
    toast.info("Screen Recording Stopped", {
      position: 'top-left',
      hideProgressBar: true,
      autoClose: 1500
    })
    mediaRecorder.current = null
  }
  const stopMediaRecorder = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop()
    }
  }

  const areaInc = (increment,count) => {
    let vidWidth = 800;
    let vidHeight =  600;
    let i = 0;
    let w = 0;
    let h = increment * 0.5625 + (10 * 2);
    while (i < count) {
      if ((w + increment) > vidWidth) {
        w = 0;
        h = h + (increment * 0.5625) + (10 * 2);
      }
      w = w + increment + (10 * 2);
      i++;
    }
    if (h > vidHeight || increment > vidWidth) return false;
    else return increment;
  }

  const getMax = (inp='') => {
    //console.log("peersjum"+peersRef.current.length)
  /* let jumlah =  peersRef.current.length + 1;
    if(inp !== '') {
      jumlah = inp + 1;
    }*/
    const jumlah = countUsers
    //console.log("jumlah"+jumlah)
    let max = 0
    let i = 1
    while (i < 5000) {
      let area = areaInc(i,jumlah);
      if (area === false) {
        max = i - 1;
        break;
      }
      i++;
    }
    max = max - (10 * 2);
    return max;
  }

  function resizeVideo(inp=0)
  {
    let count = peersRef.current.length + 1;
    if(inp !== 0) {
      count = inp + 1;
    }
    console.log(count)
    let max = 0
    let i = 1
    while (i < 5000) {
      let area = areaInc(i,count);
      if (area === false) {
        max = i - 1;
        break;
      }
      i++;
    }
    max = max - (10 * 2);
    setStyleOwnDiv({margin: "10px", width: max + "px", height : (max * 0.5625) + "px"})
  }

  const onCloseJoinDialog = (val) =>{
      setShowAlertJoin(val)
  }

  const onLeftActionDialog = () => {
    socketRef.current.emit("confirm permit", {userSocketId: userJoinName.socket, statusPermit: false});
    setShowAlertJoin(false)
  }

  const onRightActionDialog = () => {
    socketRef.current.emit("confirm permit", {userSocketId: userJoinName.socket, statusPermit: true});
    setShowAlertJoin(false)
  }

  return (
    <div className="meet-parent">

      {/* Transcript component */}
      <Transcript
        transcripts={transcripts}
        openTranscripts={openTranscripts}
        setOpenTranscripts={setOpenTranscripts}
        transcriptsRef={transcriptsRef} />

      <div className="meet-outer-layout">
        <div className="meet-icons">
          <img alt="Logo ALS" src={MicrosoftTeams}
            className="meet-teams-logo"
          />
          <div className="meet-top-option-box">
            <h4>{roomID} | {date.toLocaleTimeString()}</h4>
          </div>
        </div>
            <div className="container-room">
              <div className="screen-share-box" style={{ display: screenSharingEnabled.enabled ? 'flex' : 'none' }}>
                <video ref={screenStreamComponent} className="video-box-focus"
                       muted={socketRef.current && screenSharingEnabled.presenter === socketRef.current.id}
                       playsInline id="share-screen-user"
                       autoPlay />

              </div>
              <div className="focus-box" style={{ display: layout === 'focus' && !screenSharingEnabled.enabled ? 'flex' : 'none' }}>
                <video ref={focusModeScreen} className="video-box-focus"
                       playsInline autoPlay muted={true} />
                <div ref={focusNameModeScreen} className="member-name">
                  {namaUser}
                </div>
              </div>
              <div className="left-cont"  style={{ width: screenSharingEnabled.enabled ? '30%' : layout === 'focus' ? '30%' : '100%' }}>
                <div ref={divWidthRef} className={screenSharingEnabled.enabled || layout === 'focus' ? "members-row" : "video-cont"} id="vcont">
                  {screenSharingEnabled.enabled || layout === 'focus' ? <div className="header-participant">Meeting Participant({countUsers})</div> : ''}
                  <div id="ownCamId" style={{width: getMax()+"px", height: screenSharingEnabled.enabled || layout === 'focus' ? "200px" : (getMax() * 0.5625)+"px", margin: "10px", backgroundColor:"#5e5873", borderRadius: "12px", cursor: "pointer"}} className="video-box-br" onClick={() => {clickOwnStreamFocusMode()}}>
                    <div className="off-cam-container" style={{ display: camera ? 'flex' : 'none' }}>
                      <UserVideo hostRef={hostRef} id="user-own-video" muted={true} style={style} />
                      <div className="canvas-audio">
                        <canvas width="120%" height="30px" id="ownAudioOnCameraId"></canvas>
                      </div>
                      <div className="member-name">
                        {namaUser}
                      </div>
                    </div>
                    {camera
                        ? null :
                        <div className="off-cam-container">
                          <div className="member-name-initial">
                            {namaUser.slice(0, 1)}
                          </div>
                          <div className="canvas-audio">
                            <canvas width="120%" height="30px" id="ownAudioId"></canvas>
                          </div>
                          <div className="member-name">
                            {namaUser}
                          </div>
                        </div>
                    }
                  </div>
                  {

                    peersRef.current.map(peer => {
                      return <CreateRef peer={peer} style={{width: getMax()+"px", height: screenSharingEnabled.enabled || layout === 'focus' ? "200px" : (getMax() * 0.5625)+"px", margin: "10px", backgroundColor:"#5e5873", borderRadius: "12px"}} styleCam={style} focusRef={{videoRef:focusModeScreen, nameRef: focusNameModeScreen}} />

                    })
                  }
                  {FrontalTranscript?<div className="Transcript-down-position">
                    {FrontalTranscript}
                  </div>:null}

                </div>
                <div className="meet-options">
                  <div className="menu-options">
                    <Icon
                        icon={emojiIcon}
                        className="meet-controllers"
                        onClick={() => {
                          const showToggle = !showModal.emojiMenu
                          setShowModal({...showModal, emojiMenu: showToggle})
                        }} />
                    {showModal.emojiMenu ?
                    <ul className="dropdown-emoji-options">
                      <li><img onClick={() => {createAnimation('like', namaUser)}} style={{width: "30px", height: "30px"}} src={likeIconEmoji} alt="like emoji"/></li>
                      <li><img onClick={() => {createAnimation('haha', namaUser)}} style={{width: "30px", height: "30px"}} src={hahaIconEmoji} alt="haha emoji"/></li>
                      <li><img onClick={() => {createAnimation('wow', namaUser)}} style={{width: "30px", height: "30px"}} src={wowIconEmoji} alt="wow emoji"/></li>
                      <li><img onClick={() => {createAnimation('support', namaUser)}} style={{width: "30px", height: "30px"}} src={supportIconEmoji} alt="support emoji"/></li>
                      <li><img onClick={() => {createAnimation('love', namaUser)}} style={{width: "30px", height: "30px"}} src={loveIconEmoji} alt="love emoji"/></li>
                    </ul>
                    : ''}
                  </div>
                  {
                    mic
                        ?
                        <Icon
                            icon={micIcon}
                            className="meet-controllers"
                            data-tip="close mic"
                            onClick={() => {

                              ToggleState("audio", mic, setMic)

                            }} />
                        :
                        <Icon
                            icon={micMute}
                            className="meet-controllers"
                            data-tip="open mic"
                            onClick={() => {

                              ToggleState("audio", mic, setMic)

                            }} />
                  }
                  <div className="disconnect-meet">
                    <Icon
                        className="meet-controllers"
                        icon={phoneOff}
                        onClick={disconnectMeet}
                    />
                  </div>
                  {
                    camera
                        ?
                        <Icon
                            icon={cameraVideo}
                            className="meet-controllers"
                            data-tip="close camera"
                            onClick={() => {
                              ToggleState("video", camera, setCamera)

                            }} />
                        :
                        <Icon
                            icon={cameraVideoOff}
                            className="meet-controllers"
                            data-tip="open camera"
                            onClick={() => {
                              ToggleState("video", camera, setCamera)

                            }} />
                  }
                  {!screenSharingEnabled.enabled
                      ? <Icon icon={shareScreenStart28Filled}
                              onClick={screenShare}
                              data-tip="Share Screen"
                              className="screen-share"
                      /> :
                      <Icon icon={shareScreenStop20Filled}
                            data-tip={socketRef.current && screenSharingEnabled.presenter === socketRef.current.id
                                ?
                                'Stop Screen Share'
                                : 'Only presenter can stop screen sharing'
                            }
                            onClick={

                              socketRef.current && screenSharingEnabled.presenter === socketRef.current.id
                                  ?
                                  screenShare
                                  :
                                  null
                            }
                            className={`screen-share ${socketRef.current && screenSharingEnabled.presenter !== socketRef.current.id
                                ?
                                'screen-share-disabled'
                                :
                                null}`}
                            style={{
                              color: '#ff0000'
                            }}
                      />
                  }
                  <Icon
                      icon={handRight}
                      className="meet-controllers"
                      data-tip="Raise Hand"
                      onClick={() => {
                        raiseHand()

                      }} />
                  <div className="menu-options">
                    <Icon
                        icon={overflowMenuVertical}
                        className="meet-controllers"
                        onClick={() => {
                            const showToggle = !showModal.modalMenu
                            setShowModal({...showModal, modalMenu: showToggle})
                        }} />
                    {showModal.modalMenu ?
                    <ul className="dropdown-meet-options">
                      <li>Change Wallpaper
                        <Switch
                            className="background-switcher"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={(val) => {
                              setCustomBackground(val)
                              if (val)
                                document.getElementsByClassName('meet-outer-layout')[0].style.backgroundImage = `url("https://picsum.photos/1920/1080")`
                              else
                                document.getElementsByClassName('meet-outer-layout')[0].style.backgroundImage = null
                            }} checked={customBackground} />
                      </li>
                      <li onClick={() => {
                        if (screenSharingEnabled.enabled && screenSharingEnabled.presenter !== socketRef.current.id)
                          return toast.dark("Only presenter can record screen", {
                            position: 'top-left',
                            hideProgressBar: true,
                            autoClose: 1500
                          })
                        if (screenSharingEnabled.enabled && recordedChunks.enabled) {
                          stopMediaRecorder()
                        }
                        else if (screenSharingEnabled.enabled && !recordedChunks.enabled) {
                          startMediaRecorder()
                        }
                        else {
                          toast.dark("Start Screen Sharing before Recording", {
                            position: 'top-left',
                            hideProgressBar: true,
                            autoClose: 1500
                          })
                        }
                      }}>
                        {screenSharingEnabled.enabled && recordedChunks.enabled ? 'Stop Screen Recording' : 'Start Screen Recording'}
                        <Icon icon={record48Regular}
                              style={{
                                color: '#eb4a4a'

                              }}
                        /></li>
                      {layout === 'grid' ?
                      <li onClick={() => {
                        focusScreen()
                      }}>Fokus Layout</li> :
                      <li onClick={() => {
                        gridScreen()
                      }}>Grid Layout</li>
                      }
                    </ul>
                    : ''}
                  </div>
                </div>
                <div className="meet-options-right">
                  <Icon
                      icon={userList}
                      data-tip="Meeting Participant"
                      className="meet-controllers"
                      onClick={() => {
                        reqShowParticipant();
                        setOpenParticipant(!openParticipant);

                      }}
                  />
                  <div className="chat-opener-wrapper">
                  <Icon
                      icon={bxsMessageAltDetail}
                      className="meet-controllers"
                      onClick={() => {
                        setOpenChat(!openChat);
                        setChatBadge(false)
                      }}
                      data-tip="Chats Panel"
                  />
                  {
                    chatBadge ?
                        <div className="chat-badge">

                        </div> : null
                  }
                  </div>
                </div>
              </div>
            </div>
       </div>
      <Chats chats={chats} sendMessage={sendMessage} openChat={openChat} setOpenChat={setOpenChat} />
      <Participants users={participant} openUser={openParticipant} setOpenUser={setOpenParticipant} setMuteUser={(val) => muteParticipant(val)} setOffVideo={(val) => offVideoParticipant(val)} />
      <ToastContainer
      pauseOnFocusLoss={false}
      />
      <AlertDialog showDialog={showAlertJoin} onClose={(val) => onCloseJoinDialog(val)} message={`${userJoinName.name} request join meeting`} title={'Request Join Meeting'} btnTextLeft={'Reject'} btnTextRight={'Approve'} onRightAction={() => onRightActionDialog()} onLeftAction={() => onLeftActionDialog()} />
      <ReactTooltip effect="solid" place="bottom" />
      <div className="previewemoji">
        {children.length > 0 ? children.map(child=>child) : ''}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    email: state.userDetails.email,
    name: state.userDetails.name,
    mic: state.userDetails.mic,
    camera: state.userDetails.camera,
    stream: state.userDetails.stream,
    videoDevices: state.userDetails.videoDevices,
    audioDevices: state.userDetails.audioDevices,
    socket: state.userDetails.socket,
    auth: state.userDetails.auth,
    isAdmin: state.userDetails.isAdmin,
    userId: state.userDetails.userId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setIsAdmin: data => {
      dispatch({
        type: 'SET_IS_ADMIN',
        isAdmin: data,
      })
    },
    setUserId: data => {
      dispatch({
        type: 'SET_USER_ID',
        userId: data,
      })
    },
    setAuth: data => {
      dispatch({
        type: 'SET_AUTH',
        auth: data,
      })
    },
    setEmail: data => {
      dispatch({
        type: 'SET_EMAIL',
        email: data,
      })
    },
    setSocket: data => {
      dispatch({
        type: 'SET_SOCKET',
        socket: data,
      })
    }
    ,
    setName: data => {
      dispatch({
        type: 'SET_NAME',
        name: data
      })
    },
    setMic: data => {
      dispatch({
        type: 'SET_MIC',
        mic: data
      })
    }
    ,
    setCamera: data => {
      dispatch({
        type: 'SET_CAMERA',
        camera: data
      })
    }
    ,
    setStream: data => {
      dispatch({
        type: 'SET_STREAM',
        stream: data
      })
    },
    setVideoDevices: data => {
      dispatch({
        type: 'SET_VIDEO_DEVICES',
        videoDevices: data
      })
    },
    setAudioDevices: data => {
      dispatch({
        type: 'SET_AUDIO_DEVICES',
        audioDevices: data
      })
    }
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Meet)