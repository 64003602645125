import {useState} from "react";
import {Input, Alert, Button} from "reactstrap";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import { prodUrl as url } from "../Config/config.json"
import {connect} from "react-redux";
import "../styles/login.css";
import parse from "html-react-parser";
const Register = () => {
    const [registerObj, setRegisterObj] = useState({ username: "", email: "", password: "" });
    const [pesanError, setPesanError] = useState('');
    const [registerBtnTxt, setRegisterBtnTxt] = useState('Daftar')
    const history = useHistory();
    const handleSubmit = () => {
        changeRegisterBtnTxt(parse('<span className="spinner-grow spinner-grow-sm text-center"></span>'))
        try {
            const name = registerObj.username
            const email = registerObj.email
            const password = registerObj.password
            axios({
                url: `${url}/signup`,
                method: 'POST',
                data: {
                    name,
                    email,
                    password
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(data => {
                changeRegisterBtnTxt("Daftar")
                toast.success("Registered Successfully", {
                    autoClose: 2000,
                    closeOnClick: false,
                    pauseOnHover: false,
                });
                setTimeout(() => {
                    history.push("/signin")
                }, 2000);

            }).catch(data=>{
                changeRegisterBtnTxt("Daftar")
                setPesanError(data.response.data.error)
            })
        } catch (e) {
            setPesanError('Terjadi kesalahan, silahkan coba lagi');
        }
    };

    const changeRegisterBtnTxt = (val) => {
        setRegisterBtnTxt(val);
    }

    return(
        <div className="container-fluid">
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="login-card">
                        <form className="theme-form login-form">
                            <div className="login-header text-center">
                                <h4>Buat Akun</h4>
                                <h6>Masukkan detail data diri Anda dibawah ini</h6>
                            </div>
                            <div className="login-social-title">
                                <h5>Sign up with Email</h5>
                                {pesanError !== '' ? (
                                    <Alert color='danger'>
                                        <div className='alert-body font-small-2'>
                                            <p>
                                                {pesanError}
                                            </p>

                                        </div>
                                    </Alert>) : null }
                            </div>
                            <div className="form-group mb-0">
                                <label>Username</label>
                                <Input type='text' onChange={(e) => {
                                    setRegisterObj({ ...registerObj, username: e.target.value });
                                }} autoFocus />
                            </div>
                            <div className="form-group" style={{marginTop: "10px"}}>
                                <label>Email</label>
                                <Input type='text' onChange={(e) => {
                                    setRegisterObj({ ...registerObj, email: e.target.value });
                                }} />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <Input type='password' onChange={(e) => {
                                    setRegisterObj({ ...registerObj, password: e.target.value });
                                }} />
                            </div>
                            <div className="form-group">
                                <div className="checkbox">
                                    <Input id="checkbox1" type="checkbox" />
                                        <label className="text-muted" htmlFor="checkbox1">Agree with <span>Privacy Policy                   </span></label>
                                </div>
                            </div>
                            <div className="form-group">
                                <Button onClick={handleSubmit} className="btn btn-primary btn-block">
                                    {registerBtnTxt}
                                </Button>
                            </div>
                            <p>Sudah punya akun? <a className="ms-2" href="/signin">Sign
                                in</a> / <a className="text-primary" href="/">Home</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        email: state.userDetails.email,
        auth: state.userDetails.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {

        setEmail: data => {
            dispatch({
                type: 'SET_EMAIL',
                email: data,
            })
        },
        setAuth: data => {
            dispatch({
                type: 'SET_AUTH',
                auth: data,
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);