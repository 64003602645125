import React, {useEffect, useState} from 'react';
import likeIcon from "../assets/images/like.png"
import hahaIcon from "../assets/images/haha.png"
import loveIcon from "../assets/images/love.png"
import supportIcon from "../assets/images/support.png"
import wowIcon from "../assets/images/wow.png"
const createAnimation=(animType)=>{
    let styleSheet = document.styleSheets[0];
    let animationName,keyframes;

    switch(animType){
        case 'container':
            animationName= `animation_c${Math.round(Math.random() * 1000)}`;
            keyframes=`@-webkit-keyframes ${animationName} {
                0% {
                    transform: translateY(0px);
                }
                100% {
                    transform: translateY(-204px);
                }
            }`;
            break;
        case 'yaxis':
            animationName= `animation_y${Math.round(Math.random() * 1000)}`;
            let randomNum = Math.random() > .5;
            let xdisplacement=(Math.random() * (1 - 0.6) + .6);
            xdisplacement=(randomNum ? 1 : -1)*((Math.round(xdisplacement*1000)/1000)+17);
            keyframes=`@-webkit-keyframes ${animationName} {
                0% {
                    animation-timing-function: ease-in-out;
                    transform: translateX(0px);
                }
                50% {
                    transform: translateX(${xdisplacement}px);
                }
                100% {
                    transform: translateX(0px);
                }
            }`;
            break;
        case 'xaxis':
            animationName= `animation_x${Math.round(Math.random() * 1000)}`;
            keyframes=`@-webkit-keyframes ${animationName} {
                0% {
                    transform: scale(0.375);
                }
                40% {
                    opacity: 1;
                    transform: scale(0.375);
                }
                100% {
                    opacity: 0;
                    transform: scale(0.1);
                }
            }`;
            break;
    }


    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    return animationName;
}
const animationend=(props)=>{
    props.animationHasEnd(props.magicNum);
}
const getRightSpace=()=>{
    let maxWidth=getWindowDimensions()['width']+100;
    return Math.ceil(Math.random() * (maxWidth));
}
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
const Emoji=props=>{
    const [iconShow,setIconShow] = useState(null)
    useEffect(() => {
        changeIconShow(props.emojiType)
    },[])
    let animationETA=((Math.random() * (7.5 - 5.5 + 1) ) + 5.5)+'s';
    let containerAnimationName=createAnimation('container'),
        yAxisAnimation=createAnimation('yaxis'),
        xAxisAnimation=createAnimation('xaxis'),
        commonStyle={
            animationIterationCount: 1,
        },axiscommonStyle={
            animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
        },
        containerStyle={
            ...commonStyle,
            right: getRightSpace(),
            bottom: '5px',
            animationName: containerAnimationName,
            animationDuration: animationETA,
        },
        yAxisStyle={
            ...commonStyle,
            ...axiscommonStyle,
            animationName: yAxisAnimation,
            animationDuration: '7.25s',
        },
        xAxisStyle={
            ...commonStyle,
            ...axiscommonStyle,
            transform: 'scale(0.375)',
            animationName: xAxisAnimation,
            animationDuration: animationETA,
        }

        const changeIconShow = (val) => {
            if(val === 'like') {
                setIconShow(likeIcon)
            } else if(val === 'haha') {
                setIconShow(hahaIcon)
            } else if(val === 'support') {
                setIconShow(supportIcon)
            } else if(val === 'love') {
                setIconShow(loveIcon)
            } else if(val === 'wow') {
                setIconShow(wowIcon)
            }

        }

    return (
        <React.Fragment>
            <div className="animationcontainer" style={containerStyle} onAnimationEnd={()=>animationend(props)}>
                <div className="animationcontainer" style={yAxisStyle}>
                    <div className="animationcontainer" style={xAxisStyle}>
                        <img style={{width: "70px", height: "70px"}} alt="like" src={iconShow} />
                        <span style={{fontSize: "29px", color: "white"}}>{props.nameUserEmoji}</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default Emoji;